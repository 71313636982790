import logo from './logo.svg';
import './App.css';
import UserObject from './Components/UserObjects/user';
import { FluentProvider, webLightTheme  } from '@fluentui/react-components';

function App() {
	return (
		<FluentProvider theme={webLightTheme }>
			<div className="App">
				<header className="App-header">
					<h1>6ixtyWings Orangeville Cash Log</h1>
				</header>
				<UserObject />
			</div>
		</FluentProvider>
	);
}

export default App;
