
import React, { useState } from "react";
import Login from "./login";
import Register from "./register";
import FormData from "../FormData/form";


export default function UserObject(){

    const[username, setUser] = useState('');
    const today = new Date().toDateString();

    return(
        <div>
            <div >
                {
                    username === ''?
                    <div className="usercss"><Login setUserName={setUser}/> <Register setUserName={setUser}/> </div>
                    :<h3>Welcome {username}</h3>
                }
                
            </div>
            <div className="formcss">
                {
                    username !== '' ?
                    <div>{today}
                    <FormData /></div>:<></>
                }
            </div>
        </div>
    )
}