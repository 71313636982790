
import React, { useEffect, useState } from "react";
import {
    TableRow,
    TableCell,
    Input
  } from "@fluentui/react-components";
  import { NumericFormat } from 'react-number-format';



export default function CurrencyData({currencyvalue, ctype, startTotal, endTotal}){

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [starttotal, setStartTotal] =useState(0);
    const [endtotal, setEndTotal] =useState(0);
    const [type, setType] =useState('bills');

    const startvalue = (data) =>{
        setStart(data.value);
    }

    const endvalue = (data) =>{
        setEnd(data.value);
    }

    useEffect(() =>{
        if (ctype === 'B')
            setType('bills');
        else
            setType('coins');
    },[])

    useEffect(() =>{
        setStartTotal(start * currencyvalue);
    },[start])

    useEffect(() =>{
        setEndTotal(end * currencyvalue);
    },[end])

    useEffect(() =>{
        startTotal(starttotal,currencyvalue);
    },[starttotal])

    useEffect(() =>{
        endTotal(endtotal,currencyvalue);
    },[endtotal])

    return(
        <TableRow>
            <TableCell>
                <span>${currencyvalue} {type}</span>
            </TableCell>
            <TableCell>
                <Input type="number" value={start} onChange={(e, d) => startvalue(d)}/>
            </TableCell>
            <TableCell className="tdnumber">
                <NumericFormat  value={starttotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale  />
            </TableCell>
            <TableCell>
            <   Input type="number" value={end} onChange={(e, d) => endvalue(d)}/>
            </TableCell>
            <TableCell className="tdnumber">
                <NumericFormat  value={endtotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
            </TableCell>
        </TableRow>
    )
}