
import React, { useEffect, useState } from "react";
import {
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableBody,
    Button,
  } from "@fluentui/react-components";
import CurrencyData from "./currency";
import { NumericFormat } from 'react-number-format';

const cashObj =[
    {
        Currency: 100,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 50,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 20,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 10,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 5,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 2,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: 1,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: .25,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: .10,
        sTotal: 0,
        eTotal: 0
    },
    {
        Currency: .05,
        sTotal: 0,
        eTotal: 0
    }
]

export default function FormData(){

    const [sttot, setStTotal] = useState(0);
    const [edtot, setEdTotal] = useState(0);
    const [cash, setCash] = useState(cashObj);

    const setStartTotal = (value,curr) =>{
        const tot = cash;
        tot.forEach(rec =>{
            if (rec.Currency === curr)
                rec.sTotal = value;
        });
        setCash(tot);
        updateTotals();
    }

    const setEndTotal = (value, curr) =>{
        const tot = cash;
        tot.forEach(rec =>{
            if (rec.Currency === curr)
                rec.eTotal = value;
        });
        setCash(tot);
        updateTotals();
    }

    const updateTotals = () =>{
        let stot = 0;
        let etot = 0;
        cash.forEach(rec =>{
            stot += rec.sTotal;
            etot += rec.eTotal;
        });
        setStTotal(stot);
        setEdTotal(etot);
    }

    return(
        <div>
            <table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>
                            Currency
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Start of Day Count
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Total
                        </TableHeaderCell>
                        <TableHeaderCell>
                            End of Day Count
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Total
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <CurrencyData currencyvalue={100} ctype={'B'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={50} ctype={'B'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={20} ctype={'B'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={10} ctype={'B'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={5} ctype={'B'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={2} ctype={'C'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={1} ctype={'C'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={.25} ctype={'C'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={.10} ctype={'C'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <CurrencyData currencyvalue={.05} ctype={'C'} startTotal={setStartTotal} endTotal={setEndTotal}/>
                    <TableRow>
                        <TableHeaderCell>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Report Total
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <NumericFormat  value={sttot} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
                        </TableHeaderCell>
                        <TableHeaderCell>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <NumericFormat  value={edtot} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
                        </TableHeaderCell>
                    </TableRow>
                </TableBody>
            </table>
            <div>
                {
                    sttot > 0 && edtot > 0 ?
                    <Button appearance="primary">Submit</Button>:
                    <Button appearance="primary">Save</Button>
                }
            </div>
        </div>
    )
}