import { Button, Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent, makeStyles, Label, Input,Dropdown, Option} from "@fluentui/react-components";
    import React, {useEffect, useState}  from "react";
    import axios from "axios";

const useStyles = makeStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
    },
});

const userObj = {
    LocationID: 0,
    UserName: '',
    UserEmail: '',
    UserPassword: ''
}

export default function Register({setUserName}){

    const styles = useStyles();
    
    const [thelocation, setLocation] = useState('');
    const [locationid, setLocationID] = useState(0);
    const [locations, setTheLocations] = useState([]);
    
    useEffect(() =>{
        axios.get("https://6ixtywingswebapi.azurewebsites.net/locations")
        .then(response =>{
            if (response.data !== null){
                setTheLocations(response.data);
            }
        })
        .catch(error => alert("Error in retreiving locations. Please contact IT"));
    },[])


    const handleSubmit = (ev) => {
        ev.preventDefault();

        userObj.LocationID = locationid;
        userObj.UserName = document.getElementById("name-input").value;
        userObj.UserEmail  = document.getElementById("email-input").value;
        userObj.UserPassword = document.getElementById("password-input").value;

        axios.post("https://6ixtywingswebapi.azurewebsites.net/isNewUser",
            JSON.stringify(userObj),
            {
                headers:{
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose"
                }
            }
        )
        .then(response =>{
            if (response.data)
            {
                axios.post("https://6ixtywingswebapi.azurewebsites.net/Register",
                    JSON.stringify(userObj),
                    {
                        headers:{
                            "accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                    .then(response =>{
                        if (response.data.statusCode === 200){
                            setUserName(userObj.UserName);
                        }
                        else
                            alert("Error in registration. Please Phil");
                    })
                    .catch(error => alert("Error in registration. Please Phil"));
            
            }
            else
                alert("user is already registered");
        }).catch(error => alert("Error in Registering. Please contact Phil"));
    };

    const setLocationValue = (data) =>{
        setLocation(data.optionText);
        setLocationID(data.optionValue);
    }

    const resetloaction = () =>{
        setLocation('');
    }

    return (
            <Dialog>
                <DialogTrigger disableButtonEnhancement>
                    <Button>Register</Button>
                </DialogTrigger>
                <DialogSurface aria-describedby={undefined}>
                    <form onSubmit={e => handleSubmit(e)}>
                    <DialogBody>
                        <DialogTitle>Register - {thelocation}</DialogTitle>
                        {
                            thelocation === ''?
                            <div className="content">
                                <label>6ixty Wings Locations</label>
                                <Dropdown onOptionSelect={(e,d) => setLocationValue(d)} value={thelocation}
                                    placeholder="Select a location"
                                >
                                    {
                                        locations.map(itm => (
                                            <Option key={itm.locationID} text={itm.locationName} value={itm.locationID}>{itm.locationName}</Option>
                                        ))
                                    }
                                    </Dropdown>
                            </div>:<></>
                        }

                        {
                            thelocation !== '' ?
                            <div className="content">
                                <DialogContent className={styles.content}>
                                    <Label required htmlFor={"name-input"}>
                                        Name
                                    </Label>
                                    <Input required type="text" id={"name-input"} />
                                    <Label required htmlFor={"email-input"}>
                                        Email address
                                    </Label>
                                    <Input required type="email" id={"email-input"} />
                                    <Label required htmlFor={"password-input"}>
                                        Password 
                                    </Label>
                                    <Input required type="password" id={"password-input"} />
                                </DialogContent>
                                <DialogActions className="fui-DialogBodybuttons">
                                <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary" onClick={resetloaction}>Close</Button>
                                    </DialogTrigger>
                                    <Button type="submit" appearance="primary">
                                        Submit
                                    </Button>
                                </DialogActions>
                        </div>:<></>
                        }
                    </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
    )
}